/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "normalize.css"
import { handleInternationalUsers } from "./src/utils/geo"
import "./src/styles/styles.scss"

export const onClientEntry = () => {
  // handleInternationalUsers()
}

