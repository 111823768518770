// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-clearing-the-air-vft-js": () => import("./../../../src/pages/clearing-the-air-vft.js" /* webpackChunkName: "component---src-pages-clearing-the-air-vft-js" */),
  "component---src-pages-educators-js": () => import("./../../../src/pages/educators.js" /* webpackChunkName: "component---src-pages-educators-js" */),
  "component---src-pages-heroes-vft-js": () => import("./../../../src/pages/heroes-vft.js" /* webpackChunkName: "component---src-pages-heroes-vft-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-just-the-facts-vft-js": () => import("./../../../src/pages/just-the-facts-vft.js" /* webpackChunkName: "component---src-pages-just-the-facts-vft-js" */),
  "component---src-pages-parents-and-community-js": () => import("./../../../src/pages/parents-and-community.js" /* webpackChunkName: "component---src-pages-parents-and-community-js" */),
  "component---src-pages-students-js": () => import("./../../../src/pages/students.js" /* webpackChunkName: "component---src-pages-students-js" */),
  "component---src-pages-virtual-field-trip-js": () => import("./../../../src/pages/virtual-field-trip.js" /* webpackChunkName: "component---src-pages-virtual-field-trip-js" */)
}

